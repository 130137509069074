"use strict";

console.log('app.js is ready');

let emailMaskElements = document.getElementsByClassName('js-masked-email');
let phoneMaskElements = document.getElementsByClassName('js-masked-phone');
if(emailMaskElements.length) {
	Inputmask('email', { "clearIncomplete": true }).mask(emailMaskElements);
}
if(phoneMaskElements.length) {
	Inputmask({"mask": "+7 999 999 99 99", "clearIncomplete": true}).mask(phoneMaskElements);
}

(function inquirer() {
	function closeModal(target) {
		if (typeof document.getElementsByClassName(target)[0] === 'undefined') {
			console.log('target element .' + target + ' not found');
		}
		else {
			document.getElementsByClassName(target)[0].classList.remove('modal_state_opened');
			document.getElementsByClassName('js-page')[0].classList.remove('page_state_modal-opened');
		}
	}

	function openModal(target) {
		if (typeof document.getElementsByClassName(target)[0] === 'undefined') {
			console.log('target element .' + target + ' not found');
		}
		else {
			document.getElementsByClassName(target)[0].classList.add('modal_state_opened');
			document.getElementsByClassName('js-page')[0].classList.add('page_state_modal-opened');
		}
	}

	const compareArrays = (a, b) => {
		return JSON.stringify(a) === JSON.stringify(b);
	};

	function checkResultByType(resultArray, type) {
		if (type === 'doc') {
			return compareArrays(resultArray, [0, 0, 0]);
		}
		else if (type === 'pharma') {
			return compareArrays(resultArray, [0, 3, 0]);
		}
		else {
			return false;
		}
	}

	const healthcare = localStorage.getItem('IS_HEALTHCARE')

	if(!healthcare) {

		openModal('js-modal-inquirer-start');

		const buttons = document.getElementsByClassName('js-modal-inquirer-button');
		let docs = [];
		let pharmas = [];

		Array.prototype.forEach.call(buttons, function (button) {
			button.addEventListener('click', function (e) {
				e.preventDefault();
				e.stopPropagation();
				const type = button.getAttribute('data-type') ? String(button.getAttribute('data-type')) : null;
				const question = button.getAttribute('data-q') ? Number(button.getAttribute('data-q')) : null;
				const value = button.getAttribute('data-value') ? Number(button.getAttribute('data-value')) : null;
				const next = button.getAttribute('data-next') ? Number(button.getAttribute('data-next')) : null;

				if (type === 'doc') {
					docs.push(value);
				}
				else if (type === 'pharma') {
					pharmas.push(value);
				}
				else {
					console.log('Unknown type of inquirer. Should by \'doc\' or \'pharma\'. Get ' + type);
				}
				if (next !== null) {
					closeModal('js-modal-inquirer-' + type + '-' + question); // Close current modal
					openModal('js-modal-inquirer-' + type + '-' + next); // Open next step modal
				}
				else // Last one step
				{
					let result = docs.length > 0 ? docs : pharmas.length > 0 ? pharmas : null;

					if (checkResultByType(result, type)) {
						closeModal('js-modal-inquirer-' + type + '-' + question); // Close current modal
						docs = [];
						pharmas = [];
						localStorage.setItem('IS_HEALTHCARE',true);
					} else {
						closeModal('js-modal-inquirer-' + type + '-' + question); // Close current modal
						openModal('js-modal-inquirer-fail'); // Open fail step modal
						docs = [];
						pharmas = [];
					}
				}
			});
		});

	}
})();

// (function countUp(){
// 	const triggers = document.getElementsByClassName('js-count-up');

// 	// The animation function, which takes an Element
// 	const animateCountUp = function(el, options) {
// 		// How long you want the animation to take, in ms
// 		const animationDuration = options.duration;
// 		// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
// 		const frameDuration = 1000 / 60;
// 		// Use that to calculate how many frames we need to complete the animation
// 		const totalFrames = Math.round( animationDuration / frameDuration );
// 		// An ease-out function that slows the count as it progresses. See https://gist.github.com/gre/1650294
// 		const linear = t => t
// 		const easeInQuad = t => Math.pow(t, 2)
// 		const easeOutQuad = t => 1 - easeInQuad(1 - t)
// 		const easeInOutQuad = t => t < .5 ? easeInQuad(t * 2) / 2 : easeOutQuad(t * 2 - 1) / 2 + .5
// 		const easeInCubic = t => Math.pow(t, 3)
// 		const easeOutCubic = t => 1 - easeInCubic(1 - t)
// 		const easeInOutCubic = t => t < .5 ? easeInCubic(t * 2) / 2 : easeOutCubic(t * 2 - 1) / 2 + .5
// 		const easeInQuart = t => Math.pow(t, 4)
// 		const easeOutQuart = t => 1 - easeInQuart(1 - t)
// 		const easeInOutQuart = t => t < .5 ? easeInQuart(t * 2) / 2 : easeOutQuart(t * 2 - 1) / 2 + .5
// 		const easeInQuint = t => Math.pow(t, 5)
// 		const easeOutQuint = t => 1 - easeInQuint(1 - t)
// 		const easeInOutQuint = t => t < .5 ? easeInQuint(t * 2) / 2 : easeOutQuint(t * 2 - 1) / 2 + .5

// 		let ease;
// 		switch (options.ease) {
// 			case 'easeInQuad' :
// 				ease = easeInQuad;
// 				break;
// 			case 'easeOutQuad' :
// 				ease = easeOutQuad;
// 				break;
// 			case 'easeInOutQuad' :
// 				ease = easeInOutQuad;
// 				break;
// 			case 'easeInCubic' :
// 				ease = easeInCubic;
// 				break;
// 			case 'easeOutCubic' :
// 				ease = easeOutCubic;
// 				break;
// 			case 'easeInOutCubic' :
// 				ease = easeInOutCubic;
// 				break;
// 			case 'easeInQuart' :
// 				ease = easeInQuart;
// 				break;
// 			case 'easeOutQuart' :
// 				ease = easeOutQuart;
// 				break;
// 			case 'easeInOutQuart' :
// 				ease = easeInOutQuart;
// 				break;
// 			case 'easeInQuint' :
// 				ease = easeInQuint;
// 				break;
// 			case 'easeOutQuint' :
// 				ease = easeOutQuint;
// 				break;
// 			case 'easeInOutQuint' :
// 				ease = easeInOutQuint;
// 				break;
// 			case 'linear' :
// 			default:
// 				ease = linear;
// 				break;
// 		}

// 		let frame = 0;
// 		const countTo = parseInt( options.countTo, 10 );
// 		// Start the animation running 60 times per second
// 		const counter = setInterval( () => {
// 			// Calculate our progress as a value between 0 and 1
// 			// Pass that value to our easing function to get our
// 			// progress on a curve
// 			const progress = ease( frame / totalFrames );
// 			// Use the progress value to calculate the current count
// 			const currentCount = Math.round( countTo * progress );

// 			// If the current count has changed, update the element
// 			if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
// 				el.innerHTML = currentCount;
// 				el.classList.add('js-animation-in-progress');
// 			}

// 			// If we’ve reached our last frame, stop the animation
// 			if ( frame === totalFrames ) {
// 				clearInterval( counter );
// 				el.classList.remove('js-animation-in-progress');
// 				el.classList.add('js-animation-is-ended');
// 			}
// 			frame++;
// 		}, frameDuration );
// 	};

// 	// The check visability function, which takes an Element
// 	function isElementInViewport (el) {
// 		var rect = el.getBoundingClientRect();

// 		return (
// 			rect.top >= 0 &&
// 			rect.left >= 0 &&
// 			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
// 			rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
// 		);
// 	}

// 	Array.prototype.forEach.call(triggers, function(trigger){
// 		trigger.setAttribute('data-count-to', trigger.innerHTML);
// 		trigger.innerHTML = 0;
// 	});

// 	// Setup isScrolling variable
// 	let isScrolling;
// 	// Listen for scroll events
// 	window.addEventListener('scroll', function ( event ) {
// 		// Clear our timeout throughout the scroll
// 		window.clearTimeout( isScrolling );

// 		// Set a timeout to run after scrolling ends
// 		isScrolling = setTimeout(function() {

// 			// Run the callback
// 			// console.log( 'Scrolling has stopped.' );
// 			Array.prototype.forEach.call(triggers, function(trigger){
// 				if(isElementInViewport(trigger) && !(trigger.classList.contains('js-animation-in-progress') || trigger.classList.contains('js-animation-is-ended'))) {
// 					let options = {
// 						duration : trigger.getAttribute('data-duration') ? trigger.getAttribute('data-duration') : '800',
// 						ease : trigger.getAttribute('data-ease'),
// 						countTo : trigger.getAttribute('data-count-to'),
// 					}
// 					animateCountUp(trigger, options)
// 				}
// 			});

// 		}, 100);

// 	}, false);
// })();

(function fixedBlocksScrollWatch() {
	function getBoundingClientRect(element) {
		var rect = element.getBoundingClientRect();
		return {
		  top: rect.top,
		  right: rect.right,
		  bottom: rect.bottom,
		  left: rect.left,
		  width: rect.width,
		  height: rect.height,
		  x: rect.x,
		  y: rect.y
		};
	  }

	const fixedElements = document.getElementsByClassName('js-scroll-fixed-block');
	const p_body = document.getElementsByClassName('js-page-body')[0];

	Array.prototype.forEach.call(fixedElements, function(fixedElement) {
		const clonedFixedElement = fixedElement.cloneNode(true);
		let wrapper = document.createElement('div');

		clonedFixedElement.classList.remove('js-scroll-fixed-block');
		wrapper.classList.add('fixed-block', 'fixed-block_is_hidden');
		wrapper.appendChild(clonedFixedElement);
		p_body.appendChild(wrapper);

		let ticking = false;

		window.addEventListener('scroll', function (e) {
			const rect = fixedElement.getBoundingClientRect();
			const coords_bottom = rect.bottom;
			const coords_top = rect.top;

			// console.log('offsetParent : ' + fixedElement.offsetParent );

			if (!ticking) {
				window.requestAnimationFrame(function () {
					if (coords_top < 0 && fixedElement.offsetParent !== null) {
						wrapper.classList.remove('fixed-block_is_hidden');
					} else {
						wrapper.classList.add('fixed-block_is_hidden');
					}

					ticking = false;
				});
				// console.log('coords_bottom : ' + coords_bottom );
				// console.log('coords_top : ' + coords_top );
				ticking = true;
			}
		});
	});
})();



(function toTopScrollWatch() {
	let ticking = false;

	window.addEventListener('scroll', function (e) {
		const last_known_scroll_position = window.scrollY;
		const button = document.getElementsByClassName('js-scroll-to-top')[0];

		if (button) {
			if (!ticking) {
				window.requestAnimationFrame(function () {
					if (last_known_scroll_position > ((window.innerHeight - 1)/2)) {
						button.classList.remove('to-top_is_hidden');
					} else {
						button.classList.add('to-top_is_hidden');
					}

					ticking = false;
				});

				ticking = true;
			}
		}
	})
})();

(function scrollTo(){
	const triggers = document.getElementsByClassName('js-scroll-to');

	Array.prototype.forEach.call(triggers, function(trigger){
		const target = trigger.getAttribute('href').split("#")[1];
		const hrefPathname = trigger.getAttribute('href').split("#")[0];

		trigger.addEventListener('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			// console.log('clicked on ' + trigger);
			if(hrefPathname === window.location.origin+window.location.pathname || hrefPathname === window.location.pathname || hrefPathname === '') {
				const elTarget = document.getElementById(target);
				if (elTarget !== null) {
					const mobNavOpened = document.getElementsByClassName('js-page')[0].classList.contains('page_state_nav-opened');
					const modalOpened =  document.getElementsByClassName('js-page')[0].classList.contains('page_state_modal-opened');

					if(mobNavOpened) {
						document.getElementsByClassName('page')[0].classList.toggle('page_state_nav-opened');
					}
					if(modalOpened) {
						document.getElementsByClassName('modal_state_open')[0].classList.toggle('modal_state_open');
						document.getElementsByClassName('page')[0].classList.toggle('page_state_modal-opened');
					}

					// const headerHeight = document.getElementsByClassName('page__header')[0].offsetHeight
					let y = elTarget.getBoundingClientRect().top + window.scrollY;

					const pageHeader = document.getElementsByClassName('page__header')[0];
					const fixedHeader = pageHeader.classList.contains('js-scroll-fixed-block');

					if(fixedHeader) {
						const headerHeight = pageHeader.offsetHeight;
						console.log('headerHeight:' + headerHeight);
						if(y > headerHeight) {
							y = y - headerHeight;
						}
					}

					window.scroll({top: y, behavior: 'smooth'});
				} else {
					console.log('target not found');
				}
			} else {
				window.location.href = trigger.getAttribute('href');
			}
		});
	});
})();

(function toggle() {
	const triggers = document.getElementsByClassName('js-toggle');

	Array.prototype.forEach.call(triggers, function(trigger){
		const toggleTargets = trigger.getAttribute('data-target').split(", ");
		const toggleClass = trigger.getAttribute('data-class').split(", ");
		// console.log(toggleTargets);
		// console.log(toggleClass);

		trigger.addEventListener('click', function(e){
			e.preventDefault();
			// console.log('clicked on ' + this.getAttribute('class'));
			const toggleTextHide = trigger.getAttribute('data-hide-text');
			const toggleVideo = trigger.getAttribute('data-video');
			let errors = [];
			// Collecting errors
			Array.prototype.forEach.call(toggleTargets, function(toggleTarget, index){
				if(typeof document.getElementsByClassName(toggleTarget)[0] === 'undefined') {
					errors.push(toggleTarget);
				}
			});
			if(errors.length > 0) {
				errors.forEach(function(error){
					console.log('target element .' + error + ' not found');
				});
			} else {
				// No erros! Do the job
				const mobNavOpened = document.getElementsByClassName('js-page')[0].classList.contains('page_state_nav-opened');

				if(mobNavOpened && !toggleClass.includes('page_state_nav-opened')) {
					// console.log('Closing sidebar');
					document.getElementsByClassName('page')[0].classList.toggle('page_state_nav-opened');
				}
				Array.prototype.forEach.call(toggleTargets, function(toggleTarget, index){
					document.getElementsByClassName(toggleTarget)[0].classList.toggle(toggleClass[index]);
					if(toggleVideo) {
						document.getElementsByClassName(toggleTarget)[0].getElementsByClassName(toggleVideo)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
					}
				});
				if(toggleTextHide) {
					trigger.setAttribute('data-hide-text',trigger.innerText);
					trigger.innerText = toggleTextHide;
				}
			}
		});
	});
})();

// (function productGallery() {
// 	const galleries = document.getElementsByClassName('js-product-gallery');

// 	Array.prototype.forEach.call(galleries, function(gallery){
// 		const mainImages = gallery.getElementsByClassName('js-product-gallery--main');
// 		const thumbImages = gallery.getElementsByClassName('js-product-gallery--thumb');

// 		Array.prototype.forEach.call(thumbImages, function(thumb){
// 			thumb.addEventListener('click', function(e){
// 				e.preventDefault();
// 				// console.log('clicked on ' + this.getAttribute('class'));
// 				const id = thumb.getAttribute('data-id');

// 				let errors = [];
// 				// Collecting errors
// 				if(typeof document.getElementsByClassName('js-product-gallery--main-' + id)[0] === 'undefined') {
// 					errors.push('js-product-gallery--main-' + id);
// 				}
// 				if(errors.length > 0) {
// 					errors.forEach(function(error){
// 						console.log('target element .' + error + ' not found');
// 					});
// 				} else {
// 					// No erros! Do the job
// 					Array.prototype.forEach.call(mainImages, function(mainImage){
// 						mainImage.classList.remove('product-gallery__main-image-list-item_is_current');
// 					});
// 					document.getElementsByClassName('js-product-gallery--main-' + id)[0].classList.add('product-gallery__main-image-list-item_is_current');
// 				}
// 			});
// 		});
// 	});
// })();

// (function cityPicker() {
// 	const	buttons	= document.getElementsByClassName('js-city-picker-button');
// 	const	cookie	= Cookies.get('city');

// 	if(typeof cookie === 'undefined') {
// 		const modal = document.getElementsByClassName('js-modal-city')[0];
// 		const page = document.getElementsByClassName('js-page')[0];
// 		if(modal) {
// 			modal.classList.add('modal_state_opened');
// 			page.classList.add('page_state_modal-opened');
// 		}
// 		// console.log('show modal');
// 	}
// 	else
// 	{
// 		// console.log('do nothing?');
// 	}
// 	// console.log(cookie);
// 	function bsnsModel() {
// 		const	models	= document.getElementsByClassName('js-business-model');
// 		let		cityID	= Cookies.get('city');

// 		if(typeof cityID !== 'undefined') {
// 			Array.prototype.forEach.call(models, function(model){
// 				if(model.getAttribute('data-city') == cityID) {
// 					model.classList.remove('business-model__numbers-list-item_ds_no');
// 				} else {
// 					model.classList.add('business-model__numbers-list-item_ds_no');
// 				}
// 			});
// 		}
// 		// console.log('business-model with cityID="' + cityID + '" showed');
// 	}
// 	Array.prototype.forEach.call(buttons, function(button){
// 		button.addEventListener('click', function(e){
// 			e.preventDefault();
// 			const cityID = this.getAttribute('data-city');
// 			let inMinute = new Date(new Date().getTime() + 1 * 60 * 1000);
// 			Cookies.set('city', cityID);
// 			// console.log(cityID);
// 			// console.log(Cookies.get('city'));

// 			const modal = document.getElementsByClassName('js-modal-city')[0];
// 			const page = document.getElementsByClassName('js-page')[0];
// 			if(modal) {
// 				modal.classList.remove('modal_state_opened');
// 				page.classList.remove('page_state_modal-opened');
// 			}
// 			bsnsModel();
// 		});
// 	});
// 	bsnsModel();
// })();

// (function tabs() {
// 	const tabs = document.getElementsByClassName('js-tabs');

// 	Array.prototype.forEach.call(tabs, function(tabs){
// 		const navItems = tabs.getElementsByClassName('nav__item');

// 		Array.prototype.forEach.call(navItems, function(navItem){
// 			const navLink = navItem.children[0];
// 			const targetID = navLink.getAttribute('href').substring(1);
// 			const target = document.getElementById(targetID);

// 			navLink.addEventListener('click', function(evt){
// 				evt.preventDefault();
// 				if(!target.classList.contains('tabs__pane_state_current')) {
// 					navItem.classList.add('nav__item_state_current');
// 					target.classList.add('tabs__pane_state_current');
// 					const sibs = Array.prototype.filter.call(target.parentNode.children, function(child){
// 						return child !== target;
// 					});
// 					Array.prototype.forEach.call(sibs, function(sib){
// 						sib.classList.remove('tabs__pane_state_current');
// 					});
// 					const sibsNavItems = Array.prototype.filter.call(navItem.parentNode.children, function(child){
// 						return child !== navItem;
// 					});
// 					Array.prototype.forEach.call(sibsNavItems, function(sibsNavItem){
// 						sibsNavItem.classList.remove('nav__item_state_current');
// 					});
// 				}
// 			});
// 		});
// 	});
// })();
